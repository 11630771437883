.App {
    text-align: center;
}

body {
    background-color: #f5f5f5;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

.hero {
    margin-top: 3rem;
    margin-bottom: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.additionalInfo {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.toggleRow {
    margin-bottom: 1.5rem;
}

.toggleLabel {
    padding-right: 0.5rem;
    vertical-align: super;
}

.toggle {
    margin: 0 1.5rem;
}

.toggle-wrap {
    display: flex;
    flex: 1;
    justify-content: center;
}

.toggle-mobile-wrap {
    display: flex;
    flex: 1;
    justify-content: center;
}

@media only screen and (max-width: 450px) {
    .toggle {
        margin: 2.5px 0;
        display: flex;
        flex: 1;
    }
    .toggle-wrap {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .toggle-mobile-wrap {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-end;
    }
}

.row-container {
    padding: 1rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    margin-bottom: 2.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    transition: all 2s linear;
    display: block;
}

.container {
    max-width: 700px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5rem;
    flex-direction: column;
}

input {
    padding: 12px 20px;
    margin: 10px 5px;
    box-sizing: border-box;
    border: 1px solid #555;
    border-radius: 1rem;
    outline: none;
    font-size: medium;
    width: 90%;
}

input[type="checkbox"] {
    vertical-align: middle;
}

input[type="submit"] {
    background-color: #ff7817;
    border-radius: 1rem;
    width: 90%;
    font-size: 120%;
    color: #ffffff;
    margin-top: 20px;
}

.orange-button {
    background-color: #ff7817;
    font-size: 1.2rem;
    border-radius: 1rem;
    color: #ffffff;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid black;
}

.image-area {
    flex: 1;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 0 auto;
    align-content: "center";
}

.App-link {
    color: #ff7817;
}

.submit-feedback p {
    color: #008800;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0;
    margin-bottom: 0.3rem;
}
